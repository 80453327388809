.library-header {
    margin: 0;
    position: relative;
}

.selection-list {
    margin: 0 0 10px;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #dedede;

    li {
        display: inline-block;
        position: relative;
        width: 100%;
        margin: 0;

        &:first-child .list-action {
            border: none;
        }
    }

    .list-action {
        margin: 0;
        background: none;
        color: #383838;
        font-weight: normal;
        box-shadow: none;
        border-top: 1px solid #f1f1f1;
        width: 100%;
        display: flex;
        padding: 8px 5px 8px 35px;
        text-shadow: none;
        line-height: 1.4rem;
        font-family: var(--charred-pepper);
        font-variation-settings: unset;
        height: auto;
        text-align: left;
        position: relative;

        .material-icons {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translate(0, -50%);
            opacity: 0.7;
        }
    }
}

.new-list-title {
    font-weight: 500;
}

.selection-list-title {
    color: #949494;
}

.create-list-modal {
    .create-list-wrapper {
        opacity: 0;
        animation: fadeIn 1s ease-in forwards;
    }
    .modal-footer {
        position: absolute;
        right: 5px;
        top: 9px;
        width: 50px;
        height: 50px;

        .modal-close {
            background: none;
            box-shadow: none;
            color: #5d5d5d;
            text-shadow: none;
            padding: 0.5rem;
            margin: 0;
            width: 35px;
            height: 35px;

            .material-icons {
                font-size: 2rem;
            }

            &:hover {
                color: #000;
            }
        }
    }

    h3 {
        font-size: 1rem;
    }

    h4 {
        padding: 0 0.6rem;
    }

    .add-list-btn {
        margin: 0;

        &:disabled {
            color: #fefefe !important;
            opacity: 0.5;
        }
    }

    .zero-list-items-msg {
        margin: 0;
        font-size: 0.9rem;
        color: #5d5d5d;
    }

    .item-selected {
        // display: none;
    }

    .list-action .material-icons {
        pointer-events: none;
    }

    &.fade-out {
        animation: fadeOut 0.6s linear forwards;
    }
}

.delete-list-modal {
    &.modal .close-modal {
        position: absolute;
        background: none;
        box-shadow: none;
        color: #5d5d5d;
        text-shadow: none;
        padding: 0.5rem;
        margin: 0;
        width: 35px;
        height: 35px;
        top: 7px;
        right: 8px;
    }

    &.modal .delete-actions {
        margin-right: 0.5rem;
    }
}

.recipe-item {
    margin: 0 0 1rem;
    position: relative;
}

.list-title {
    font-family: var(--toast-bread);
    margin: 0 0 1rem;
}

.tiny-recipe-link {
    height: 65px;
    overflow: hidden;
    border-radius: 4px;

    .tiny-recipe-link-title {
        width: 70%;
        padding-left: 0.5rem;
        font-size: 1.05rem;
    }

    .tiny-recipe-thumbnail-wrapper {
        display: flex;
        overflow: hidden;
        height: 65px;
        justify-content: center;
        position: relative;
        width: 30%;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 4px;

        img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &:hover {
        background: #f3f3f3;
        color: #000;
    }
}

.add-list.btn {
    color: #5d5d5d;
    background: #f7f7f7;
    text-shadow: none;
    font-family: var(--charred-pepper);
    width: 135px;
    height: 40px;
    margin: 0;
    border-radius: 6px;
    border: 1px solid #fefefe;
    position: absolute;
    right: 15px;
    bottom: 30px;

    &:hover {
        color: #000;
        background: #ececec78;
    }
}

.modal {
    border-radius: 4px;
}

.list-block {
    position: relative;
    padding: 1rem;
    box-shadow: 0 2px 3px #dfdfdf;
    background: #fff;
    border-radius: 3px;
    margin-bottom: 1rem;

    .list-block-list {
        max-height: 270px;
        overflow-y: scroll;
    }
}

.edit-list-controls {
    display: flex;
    justify-content: flex-end;
}

.list-settings {
    position: absolute;
    top: 8px;
    right: 10px;
    text-align: right;
    text-shadow: none;

    .list-settings-toggle {
        color: #828282;
        padding: 0.3rem;
        line-height: 27px;

        &.active {
            background: #fff;
            box-shadow: 0 1px 3px #dfdfdf;
        }

        .icon {
            height: 30px;
            width: 30px;
        }

        &:hover .icon {
            stroke: #000;
        }
    }
}

.list-controls {
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    right: -3px;
    top: 33px;
    width: 120px;
    box-shadow: 0 2px 3px #dfdfdf;
    display: none;
    z-index: 10;

    .btn {
        color: #4a4a4a;
        padding: 1rem;
        text-shadow: none;
        margin: 0;
        line-height: 1rem;
        width: 100%;
        text-align: right;
        height: 45px;
        border-radius: 0;

        &:hover,
        &:focus {
            background-color: #f1f1f1;
        }
    }

    &.show {
        animation: dropDownEffect 0.3s ease-in forwards;
        display: block;

        .overlay {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
        }

        .btn {
            z-index: 10;
        }
    }
}
