#copyright {
    padding: 0;
    font-family: var(--charred-pepper);
    color: #000;
    background: #fff;
    text-shadow: 0 1px 1px #8a8a8a59;
    font-variation-settings: 'wght' 500, 'ital' 0;

    &.open {
        .paul-wrapper {
            animation: paul-animate-y 1.6s 13s cubic-bezier(0, 1, 0.78, 0.96)
                forwards; // 13s
        }
        .paul {
            animation: paul-animate-x 1s 13s cubic-bezier(0, 1, 0.78, 0.96)
                forwards;
        }
        .holly-wrapper {
            animation: holly-animate-y 1.6s 13s cubic-bezier(0, 1, 0.78, 0.96)
                forwards;
        }
        .holly {
            animation: holly-animate-x 1s 13s cubic-bezier(0, 1, 0.78, 0.96)
                forwards;
        }
    }
}

.copyright-header {
    margin: 0 0 7px;
    font-size: 2.6rem;
}

.tips-heading {
    font-size: 2.3rem;
}

.copyright-description {
    margin: 0 0 1rem;
}

.close-copyright-guide {
    color: #fff;
    font-family: var(--charred-pepper);
    font-variation-settings: 'wght' 700, 'ital' 0;
}

.ethics-message {
    padding: 1rem 1rem 1.5rem;
    font-size: 1.1rem;
    border-left: 3px solid var(--warm-feijoa);
    border-right: 3px solid var(--warm-feijoa);
}

.tag-line {
    display: block;
    font-size: 1.6rem;
    font-family: var(--toast-bread);
    margin-bottom: 1rem;
}

.tag-line-example {
    font-size: 12px;
    display: block;
}

.inline {
    display: inline;
}

.italic {
    font-style: italic;
    font-weight: 800;
}

.ethics-list {
    li {
        position: relative;
        // padding-left: 1rem;

        // &::before {
        //     content: '';
        //     border-radius: 50%;
        //     width: 5px;
        //     height: 5px;
        //     background: var(--ab, var(--border));
        //     transform: translateX(var(--x, 0));
        //     border: 4px solid;
        //     display: block;
        //     position: absolute;
        //     left: 0;
        //     top: 9px;
        //     pointer-events: none;
        // }
    }
}

.paul-joke {
    width: 90px;
    display: inline-block;
    position: relative;
    text-align: right;

    .paul {
        position: absolute;
        right: -15px;
    }
    .paul-wrapper {
        position: absolute;
        right: 47px;
    }
    .holly {
        position: absolute;
        left: -12px;
    }
    .holly-wrapper {
        position: absolute;
        left: 12px;
    }
}

@keyframes paul-animate-x {
    0% {
        transform: translate(0, 0px);
    }

    100% {
        transform: translate(-33px, 0px);
    }
}

@keyframes paul-animate-y {
    0% {
        transform: translate(0, 0px);
        opacity: 1;
    }

    50% {
        transform: translate(0, -15px);
        opacity: 0.5;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes holly-animate-x {
    0% {
        transform: translate(0, 0px);
    }

    100% {
        transform: translate(29px, 0px);
    }
}

@keyframes holly-animate-y {
    0% {
        transform: translate(0, 0px);
        opacity: 1;
    }

    50% {
        transform: translate(0, 15px);
        opacity: 0.5;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.progress {
    background-color: #fff;

    .determinate {
        background-color: var(--warm-grapefruit);
        transition: all 0.3s linear;
        width: 0;
        will-change: width, background-color;
    }
}

.recipe-tags {
    .input {
        color: #000;

        &::-webkit-input-placeholder {
            color: #8c8c8c;
        }
    }

    &.max-tags {
        border-color: #dedede;

        .input {
            display: none;
        }
    }
}

.tags-wrapper {
    position: relative;
    width: 100%;
    display: inline-block;
}

.tags-label {
    font-size: 0.9rem;
    color: #4a4a4a;
}

.tag-counter-wrapper {
    margin-top: -12px;
    position: absolute;
    right: 0;
    color: #4a4a4a;
}

.chip {
    background-color: #f5f5f5;
    &:focus {
        background-color: var(--warm-grapefruit);
    }
    &:hover {
        // background-color: var(--warm-grapefruit);
        background-color: none;
    }
}

.chips.focus {
    box-shadow: none;
}

.user-search-title {
    font-size: 0.9rem;
    margin: 0 0 3px;
    font-weight: 400;
    color: #4a4a4a;
}

.filtered-users {
    max-height: 220px;
    overflow-y: scroll;

    li {
        margin: 0;
    }
}

.mention-btn.clean {
    color: #000;
    text-shadow: none;
    font-weight: 400;
    font-variation-settings: initial;
    font-size: 1rem;
    height: 40px;
    padding-left: 40px;
    margin: 0 3px 0 0;
    width: 100%;
    text-align: left;
    line-height: 1rem;

    &:hover {
        text-decoration: underline;
    }

    .author-thumbnail-frame {
        width: 30px;
        height: 30px;
        border: 1px solid #a7a7a7;
        position: absolute;
        left: 2px;
        top: 5px;

        .no-avatar {
            font-size: 3rem;
            line-height: 2.5rem;
            margin: 0 0 0 -6.5px;
        }
    }
}

.mention-chip {
    padding: 0 25px 0 37px;
    position: relative;
    margin-bottom: 1rem;
    line-height: 1rem;

    &:hover {
        cursor: pointer;
    }

    .author-thumbnail-frame {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 1px;
        top: 1px;
        border: none;

        .no-avatar {
            font-size: 3rem;
            line-height: 2.5rem;
            margin: 0 0 0 -6.5px;
        }
    }
    .close {
        position: absolute;
        top: 0;
        right: 7px;
    }

    .chip-name {
        display: table-cell;
        vertical-align: middle;
        height: 32px;
    }
}
.no-users-found {
    color: #4d4d4d;
    font-size: 0.9rem;
}

.user-selected {
    margin: 0 0 0.6rem;
    font-size: 0.8rem;
}

/** WYSIWYG **/

.RichEditor-root {
    margin-top: 1rem;
    // border: 1px solid #e0e0e0;
}

.RichEditor-controls,
.RichEditor-editor {
    position: relative;
    width: 100%;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        border: 1px solid;
        opacity: 0.1;
    }
}

.RichEditor-controls {
    display: flex;
    height: 3.3rem;
    justify-content: center;
    // margin: 0 0 1rem;
}

.RichEditor-styleButton {
    background: #b7b7b7;
    margin-right: 4px;
    opacity: 0.5;

    &.RichEditor-activeButton,
    &.RichEditor-activeButton:hover {
        // background: #5f7bb9;
        opacity: 1;
    }

    &:hover {
        background: #5f7bb9;
        cursor: pointer;
    }
}

.DraftEditor-root {
    padding: 1rem 0;
}

.RichEditor-helper-text {
    font-size: 0.8rem;
    color: #383838;
}
