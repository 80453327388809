.user-mention-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mention-block {
    margin: 0 0.7rem 0 0;
}

.mention-name {
    color: #555555;
    font-size: 0.95rem;
}
.mention-title {
    font-size: 11.2px;
    color: #3d3d3d;
}
