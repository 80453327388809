:root {
    --warm-apple: #ed2f3b; // old ed2f3b
    --warm-banana: #fad089;
    --warm-carrot: #ff9d5b;
    --warm-date: #f56349;
    --warm-elderberry: #398181;
    --warm-feijoa: #5da758;
    --warm-grapefruit: #ed2f3b8c;
    --warm-huckleberry: #435681;

    // Transitions
    --smooth-whiskey: 0.6s all cubic-bezier(0.6, 0.13, 0.23, 0.96); //

    // Fonts
    --charred-pepper: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
        Helvetica, Arial;
    --toast-bread: 'Lora', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    --with-butter: 'wght' 600, 'ital' 0;
}
.warm-apple {
    background-color: var(--warm-apple);
}

.warm-banana {
    background-color: var(--warm-banana);
}

.warm-carrot {
    background-color: var(--warm-carrot);
}

.warm-date {
    background-color: var(--warm-date);
}

.warm-elderberry {
    background-color: var(--warm-elderberry);
}

.warm-feijoa {
    background-color: var(--warm-feijoa);
}
.warm-grapefruit {
    background-color: var(--warm-grapefruit);
}

footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.footer-links {
    display: flex;
    margin: 0;

    a {
        color: #848484;

        &:hover {
            color: #4d4d4d;
        }
    }
}

.rules-wrapper {
    width: 80%;
    margin: 0 auto;
}

.twitter-link:hover {
    .twitter-bird {
        transition: var(--smooth-whiskey);
        stroke: #2aa9e0;
    }
}

.twitter-logo {
    width: 25px;
    height: 25px;
}

.temp-footer {
    width: 100%;
    margin: 0;
    padding: 0.4rem 0;
    text-align: center;
    color: #00000014;
    text-shadow: none;
}

body {
    margin: 0;
    // font-family: var(--toast-bread);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.3s ease-in-out;
    color: #000;
    // text-shadow: 0 1px 1px #dedede;
    font-size: 1rem;
    position: relative;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'Lora';
    src: url(./fonts/Lora-Italic-VariableFont_wght.ttf);
}

header {
    text-align: center;
    width: 200px;
    margin: 0 auto 0 -100px;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
}

// .signed-in {
//     position: absolute;
//     top: 0;
//     left: 0;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    // border: 1px solid green;
    -webkit-text-fill-color: #000;
    // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5s ease-in-out 0s;
}

h1 {
    font-size: 3.2rem;
}

h2 {
    font-size: 2.2rem;
}

h3 {
    font-size: 1.8rem;
}
h4 {
    font-size: 1.4rem;
}

.disable-scroll {
    overflow: hidden;
}

.login-controls {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.app {
    background-image: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.007) 50%,
            transparent 50%
        ),
        linear-gradient(rgba(0, 0, 0, 0.007) 50%, transparent 50%);
    background-size: 50px 50px;
    min-height: 100vh;
    padding-bottom: 4rem;
    // width: 100vw;
}

.page-wrapper {
    background-size: 50px 50px;
    // padding: 5rem 0;
    padding: 6rem 0;
    transition: 0.6s background cubic-bezier(0.6, 0.13, 0.23, 0.96);

    &.recipe-page,
    &.account-page,
    &.home-page,
    &.admin-page,
    &.library-page,
    &.edit-profile-page {
        &.author-active {
            padding: 7rem 0;
        }
    }
}

.edit-profile-heading {
    font-size: 1.5rem;
}

.page-heading {
    font-family: var(--toast-bread);
    font-variation-settings: var(--with-butter);
}

.signedout-links {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;

    li {
        margin: 0;

        span {
            display: inline-block;
        }
    }
}

.signedin-links {
    text-align: right;

    li {
        display: none;
        background: #fff;
        padding: 0.3rem 0.9rem;
        margin: 0;

        &:first-child {
            display: block;
            padding: 0;
            background: 0;
        }
    }

    &:hover,
    &:focus,
    &:focus-within {
        li {
            display: block;
        }
    }
}

.profile-link,
.signout-link,
.register-link,
.signin-link {
    height: 4rem;
    width: 4rem;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    line-height: 1rem;
    text-decoration: none;
    position: relative;
    color: #000;
    overflow: hidden;
    font-size: 1rem;
    // padding-top: 5px;

    i {
        display: inline-block;
        font-size: 2rem;
    }

    &:hover {
        color: #000;

        .material-icons {
            color: var(--warm-date);
        }
        .icon {
            stroke: var(--warm-date);
        }
    }
}

.nav-link,
.profile-link {
    &.active {
        color: var(--warm-date);
        &:hover {
            color: var(--warm-date);
        }

        .nav-text {
            color: #000;
        }
    }
}

.home-link {
    &::before {
        // content: '';
        height: 8px;
        width: 5px;
        display: inline-block;
        position: absolute;
        left: 50%;
        margin-left: -2.5px;
        top: 18px;
        background: #cecece;
        // box-shadow: 0px 1px 0.01px #565656;
    }

    &.active::before {
        content: initial;
    }
}

.navigation {
    position: absolute;
    top: 1px;
    left: 0;
    z-index: 2;
    // display: none;

    nav {
        width: 100%;
        height: inherit;
        display: flex;
        flex-flow: row nowrap;
        background: #fff0;
        box-shadow: none;
    }

    a {
        height: 4rem;
        width: 4rem;
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        line-height: 1rem;
        text-decoration: none;
        position: relative;
        color: #000;
        overflow: hidden;
        font-size: 1rem;

        .material-icons {
            height: 42px;
            width: 42px;
            line-height: 42px;
            position: absolute;
            left: 50%;
            margin-left: -21px;
            top: -4px;
            pointer-events: none;
            font-size: 2rem;
        }

        &.current {
            // color: var(--warm-elderberry);

            i {
                color: var(--warm-elderberry);
            }
        }

        &:hover {
            color: #000;

            .icon {
                stroke: var(--warm-date);
            }

            &.home-link::before {
                box-shadow: none;
                background: none;
            }
        }
    }

    // span {
    //     position: absolute;
    //     left: 50%;
    //     bottom: 7px;
    //     line-height: 1rem;
    //     transform: translate(-50%, 0px);
    // }
}

.nav-link {
    .icon {
        height: 33px;
        width: 33px;
        margin-top: 5px;
    }

    &.active {
        // color: var(--warm-elderberry);

        .icon {
            stroke: var(--warm-date);
        }
    }
}

.homepage-create-button.btn {
    font-family: var(--toast-bread);
    font-variation-settings: var(--with-butter);
    background-color: var(--warm-feijoa);
    text-shadow: none;
    font-size: 1.15rem;
    padding: 0.5rem 1.7rem;
    height: 50px;
    font-variation-settings: var(--with-butter);
    transform: perspective(100px) translate3d(0, 0, 1px);

    &:hover {
        background-color: var(--warm-feijoa);
        color: #fff;
        transform: perspective(100px) translate3d(0, 0, 2px);
    }
}

.homepage-sec-msg {
    font-weight: 500;
}

.login-page,
.register-page {
    height: 100%;
    position: fixed;
    width: 100%;
    padding-top: 5rem;
}

.author-toolbar {
    margin: 0;
    background: var(--warm-banana);
    padding: 1.4rem 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    font-family: var(--charred-pepper);
    font-variation-settings: var(--with-butter);
}

.edit-recipe-link {
    text-decoration: underline;
    font-weight: bold;

    &:hover {
        color: var(--warm-date);
    }
}

.enter-cm-btn {
    position: absolute;
    top: 0;
    margin-top: 35px;
    right: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 6px;

    &:active i,
    &:hover i {
        color: var(--warm-banana);
        transform: perspective(100px) translate3d(0, 0, 15px);
        transition: all 0.3s ease-in-out;
    }
    &.top {
        display: none;
    }
}

.zebra .btn.enter-cm-btn {
    &:hover,
    &:focus {
        background: #fff;
        color: #000;
        text-shadow: 0 1px 1px #d6d6d6;
        font-family: var(--charred-pepper);
    }
}

.exit-cm-btn {
    display: none;
    position: absolute;
    top: 0;
    right: 16px;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
    padding: 0 0.6rem 0 0.4rem;
}

.cm-view {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    // padding: 16px 7px;
    left: 50%;
    transform: translate(-50%, 0);

    i {
        margin-right: 3px;
    }

    &:active {
        top: auto;
        bottom: 1px;
    }
}

.toggle-theme {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    display: none;
    color: #000;
    background: none;
    box-shadow: none;
    height: 35px;
    width: 35px;
    justify-content: center;

    &::before {
        content: '';
        width: 14px;
        height: 15px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -46%);
        z-index: -1;
        border-radius: 7px;
    }
    i {
        font-size: 25px;
    }
}
.glide__arrow--right {
    right: 0;
    position: absolute;

    &:active {
        top: initial;
    }
}

.recipe-star {
    position: absolute;
    right: 12px;
    top: 6px;
    font-size: 2rem;

    &.btn:hover {
        background: none;
    }

    .material-icons {
        font-size: 3rem;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    }

    &.recipe-starred {
        color: var(--warm-banana);
    }

    &.recipe-unstarred {
        color: #000;
    }
}

.starring-error {
    position: fixed;
    top: -45px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1rem;
    text-align: center;
    opacity: 0;
    background-color: var(--warm-banana);
    border-radius: 3px 3px 3px 3px;

    &.show {
        animation: fadeIn 0.5s ease-in-out forwards;
        transform: translate(-50%, 50px);
        transition: 0.5s all ease-in-out;
    }
}

.toggle-ingredients {
    display: none;
    position: fixed;
    top: 0;
    left: 16px;
    padding-right: 22px;
    margin-top: 16px;

    i {
        position: absolute;
        right: 4px;
        top: 1px;
        transform: rotateX(180deg);
        pointer-events: none;
    }

    &.showing-list i {
        transform: none;
    }
}

.recipe-page {
    .recipe {
        opacity: 0;
        animation: fadeIn 0.5s linear forwards;
    }
}

.recipe {
    position: relative;
    font-size: 1.1rem;
}

.total-time-wrapper,
.servings-size-wrapper,
.difficulty-wrapper {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 1rem;
    text-transform: capitalize;
    justify-content: center;

    .material-icons {
        margin: 4px 4px 0 0;
        font-size: inherit;
        font-size: 2rem;
    }

    .icon {
        height: 33px;
        width: 33px;
        margin-right: 3px;
    }
}

.total-time-wrapper {
    align-items: flex-start;
}

.total-time {
    font-size: 0.9rem;

    .time-text {
        display: inline-block;
        font-weight: 500;
    }
    .label {
        margin-right: 5px;
        color: #4a4a4a;
    }
}

.recipe::after {
    background: url('https://firebasestorage.googleapis.com/v0/b/mymothersrecipes-e0eea.appspot.com/o/test-wallpaper-scaled.jpg?alt=media&token=4317d120-df5b-4447-9168-df37647443ea')
        no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(0.35);
    opacity: 0;
    transition: var(--smooth-whiskey);
}

.collapsible {
    box-shadow: none;

    i {
        text-shadow: none;
    }
}

.play-audio {
    display: none;
    padding: 0 0.5rem;
    margin: 0 0.5rem;
    vertical-align: text-bottom;
}

.cooking-mode {
    background: #fff;

    .page-wrapper,
    .page-wrapper.author-active {
        // background: none;
        padding: 0;
        font-family: var(--charred-pepper);
    }
    .temp-header {
        text-align: center;
        font-size: 2rem;
        width: 70%;
        margin: 5rem auto 10px;
        text-decoration: underline;
        color: #fff;
    }
    .recipe::after {
        // height: 100%;
        opacity: 1;
        // animation: fadeIn 1.5s ease;
    }

    .app,
    .recipe-page {
        background: none;
    }

    .btn {
        font-family: var(--charred-pepper);
    }

    .play-audio {
        display: inline;
    }

    &.zebra {
        .btn {
            font-family: var(--charred-pepper);
            background: #313131;

            &.toggle-theme {
                &::before {
                    background-color: var(--warm-elderberry);
                }
                i {
                    color: var(--warm-feijoa);
                }
            }
        }

        .toggle-theme {
            background: none;
            box-shadow: none;
        }

        .recipe::after {
            // animation: fadeIn 0.5s linear forwards;
            opacity: 0;
            // transform: none;
            // height: 100%;
            // background-image: none;
            // background-color: #fff;
            // filter: none;
        }
        .temp-header {
            color: #000;
            text-shadow: none;
        }
        .steps-list {
            text-shadow: 0 1px 1px #d6d6d6;
            .step {
                color: #000;

                p {
                    text-shadow: none;
                }
            }
        }
        .ingredients {
            color: #000;
            .ingredient {
                &.checkbox.checked::after {
                    border-right: 3px solid #000;
                    border-bottom: 3px solid #000;
                }
            }
            &.temp-show {
                background: #fff;
            }
        }
        .checklist-helper {
            color: #000;
        }
        .cm-step-timer {
            button {
                // background: #000;
            }
        }
    }

    .recipe-header {
        margin: 0;
    }

    .cooking-mode-controls {
        width: 100%;
    }

    .temp-hide,
    header {
        display: none;
    }

    .temp-large {
        font-size: 1.5rem;

        &.steps {
            width: 100%;
        }
    }

    .temp-show {
        display: block;
    }

    .ingredients {
        display: none;
        position: fixed;
        top: 53px !important;
        left: 15px !important;
        background: #fff;
        padding: 1rem;
        width: 50%;

        border-radius: 6px;
        border: 1px solid #00000014;

        // &:before {
        //     content: '';
        //     bottom: 0;
        //     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        //     filter: blur(6px);
        //     height: 94%;
        //     left: 3%;
        //     position: absolute;
        //     width: 94%;
        //     z-index: -1;
        // }

        h3 {
            display: none;
        }

        .ingredients-list {
            margin: 0.9rem 0 0;
        }

        .cm-ingredients-overlay {
            left: 0;
            opacity: 1;
            animation: fadeIn 0.5s ease-in;
        }

        .ingredient {
            margin: 0 0 0.5rem 0;
            font-weight: 500;

            &::before {
                top: 10px;
            }
        }
        &.temp-show {
            display: block;
            animation: dropDownEffect 0.2s ease-in-out;
            box-shadow: 0px 3px 6px #0000002b;
            z-index: 1;
        }
    }

    .steps-list {
        list-style: none;
        margin: 0;
        padding: 0;
        color: #fff;
        // opacity: 0;
        // animation: fadeInAndMoveUp 0.5s forwards;

        .step {
            counter-increment: steps;
            margin-bottom: 2rem;

            p {
                margin: 0 0 1.5rem;
                text-shadow: 0 1px 1px #2d2d2d;
            }

            &::before {
                content: 'Step ' counter(steps);
                // text-decoration: underline;
                position: initial;
                font-size: 2rem;
                left: inherit;
                top: inherit;
                width: auto;
                text-align: initial;
            }
        }
    }

    .checklist-helper {
        margin: -10px 0 0 0;
    }

    .exit-cm-btn,
    .toggle-ingredients,
    .step-view,
    .toggle-theme {
        display: flex;
    }
    .enter-cm-btn.middle,
    .enter-cm-btn.top {
        display: none;
    }

    .list-view {
        display: none;
    }

    .glide__arrow {
        display: none;
    }

    &.glide-active {
        .step-view {
            display: none;
        }
        .list-view {
            display: flex;
        }
        .glide__arrow {
            display: initial;
        }
    }
}

.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    border: 0 !important;
}

a {
    color: #000;

    &:hover {
        color: #999;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;

    li {
        margin: 0 0.5rem 0 0;
    }
}

button,
.input-field.file-field .btn {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    // background: linear-gradient(to bottom, #389ed5 0%, #389ed5 100%);
    background: var(--warm-huckleberry);
    border: 0;
    color: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    height: 32px;
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    font-family: 'Lora', sans-serif;
    font-weight: 600;
    font-size: 14px;

    &:active {
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
    }

    &:hover {
        background: #566fa6;
    }
}

// Recipe page
.checklist-helper {
    margin: -15px 0 0 0;
    font-size: 0.8rem;
    position: absolute;
}

.ingredients-list {
    display: flex;
    flex-flow: column wrap;
    font-family: var(--charred-pepper);

    .ingredient {
        padding: 0 0 0.5rem 1.3rem;
        position: relative;
        // order: 3;

        &::before {
            content: '';
            border-radius: 50%;
            width: 5px;
            height: 5px;
            background: var(--ab, var(--border));
            transform: translateX(var(--x, 0));
            border: 3px solid #6f6f6f;
            display: block;
            position: absolute;
            left: 3px;
            top: 9px;
            pointer-events: none;
        }

        &.checkbox {
            &::after {
                content: '';
                left: 4px;
                top: 4px;
                display: block;
                position: absolute;
                border-right: 2px solid #383838;
                border-bottom: 2px solid #383838;
                width: 6px;
                height: 10px;
                transition: all 0.3s ease-in-out;
                transform: perspective(10px) translate3d(0, 0, 1px)
                    rotate(45deg);
                box-shadow: 1px 1px 1px #565656;
                opacity: 0;
                pointer-events: none;
            }

            &:hover {
                cursor: pointer;

                &::after {
                    // opacity: 0.5;
                }
            }

            &.checked {
                // order: 2;
                // transition: order 1.5s;

                &::before {
                    opacity: 0.3;
                }
                &::after {
                    transform: perspective(10px) translate3d(0, 0, 0px)
                        rotate(45deg);
                    opacity: 1;
                    transition: opacity 1.5s 0.5s;
                }

                &:hover::before {
                    // text-decoration: line-through;
                    content: '';
                }

                &.used {
                    text-decoration: line-through;
                    opacity: 0.6;
                    // order: 1;
                    color: #383838;
                }
            }
            &.animate-list-item {
                // animation: fadeIn 1.5s 0.5s, moveupAndFadeOut 0.5s;
                animation: fadeIn 0.5s ease-in;
            }
        }
        &:last-child {
            // margin: 0;
        }
    }
}

.cm-ingredients-overlay {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    z-index: -1;
    // background: #00000042;
    opacity: 0;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'],
    input[type='radio'] {
        --active: #275efe;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, 0.3);
        --border: #bbc1e1;
        --border-hover: #275efe;
        --background: #fff;
        --disabled: #f6f8ff;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        &:after {
            content: '';
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
                opacity var(--d-o, 0.2s);
        }
        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
            &:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }
            & + label {
                cursor: not-allowed;
            }
        }
        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    --bc: var(--border-hover);
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
            width: 21px;
            &:after {
                opacity: var(--o, 0);
            }
            &:checked {
                --o: 1;
            }
        }
        & + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }
    }
    input[type='checkbox'] {
        &:not(.switch) {
            border-radius: 7px;
            &:after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 7px;
                top: 4px;
                transform: rotate(var(--r, 20deg));
            }
            &:checked {
                --r: 43deg;
            }
        }
        &.switch {
            width: 38px;
            border-radius: 11px;
            &:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
            }
            &:checked {
                --ab: var(--active-inner);
                --x: 17px;
            }
            &:disabled {
                &:not(:checked) {
                    &:after {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
    input[type='radio'] {
        border-radius: 50%;
        &:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, 0.7));
        }
        &:checked {
            --s: 0.5;
        }
    }
}

@keyframes dropDownEffect {
    0% {
        will-change: opacity, translate3d;
        opacity: 0;
        transform: translate3d(0, -2.5%, 0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeIn {
    0% {
        will-change: opacity;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        will-change: opacity;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeInAndMoveUp {
    0% {
        will-change: opacity, translate3d;
        opacity: 0;
        transform: translate3d(0, 10px, 0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes moveupAndFadeOut {
    0% {
        will-change: opacity;
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translate3d(0, -10px, 0);
    }
}

@keyframes sendIt {
    0% {
        z-index: initial;
    }
    100% {
        z-index: -1000;
    }
}

.thumbnail-frame .material-placeholder {
    position: relative;
    min-height: 155px;
    max-height: 265px;
    display: flex;
    overflow: hidden;
    border-radius: 6px;
    margin-bottom: 2rem;

    &.large-view {
        overflow: initial;

        &::before {
            opacity: 0;
        }
    }
    &::before {
        transition: var(--smooth-whiskey);
    }
}
.thumbnail-image {
    width: 100%;
    object-fit: cover;
    height: auto;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 2px dashed #eeeeee;
    border-radius: 2px;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.recipe-header {
    padding-bottom: 0.5rem;
}

.recipe-title {
    font-family: var(--toast-bread);
    margin: 5px 0;
    width: 83%;
    font-variation-settings: 'wght' 700;
}

.steps-cm-wrapper {
    position: relative;
    .enter-cm-btn {
        margin: 0;
        right: 0;
        display: none;
    }
}

.bottom-cm-wrapper {
    // position: fixed;
    // bottom: 0;
    // left: 50%;
    // transform: translate(0, -50%);

    .enter-cm-btn {
        left: 0;
        font-size: 1.2rem;
        // padding: 1rem 1rem 1rem 0.7rem;
        height: 50px;
        min-width: 170px;
        max-width: 190px;
        background: var(--warm-feijoa);
        position: relative;
        margin: 3rem 0 0;

        &:hover {
            background: #67ba62;
        }
    }
}

.recipe-section-heading {
    font-variation-settings: var(--with-butter);
    font-size: 2.4rem;
    margin: 0 0 2rem;
}

.description {
    font-family: var(--toast-bread);
    margin: 0;
}

.full-details p {
    margin: 0 0 1.4rem;
}

.collapsible-header {
    background-color: unset !important;
}
.collapsible li {
    padding: 0;
    margin: 0;
}
.collapsible-body {
    padding: 1rem;
}

.author-thumbnail-frame {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 30px;
    overflow: hidden;
    border: 2px solid #fff;

    img {
        width: 100%;
    }

    .no-avatar {
        font-size: 5rem;
        margin: 0 0 0 -13px;
        line-height: 4rem;
    }
}

.profile-thumbnail-frame {
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 30px;
    overflow: hidden;
    border: 1px solid #fff;
    margin: 12px auto 0;

    img {
        width: 100%;
    }
}

.author-wrapper {
    display: flex;
    align-items: center;
}

.author-thumbnail {
    width: 100%;
    height: auto;
}
.author-block {
    display: flex;
    flex-direction: column;
    margin-left: 0.7rem;
}
.author-name {
    font-variation-settings: var(--with-butter);
    display: inline-block;
    clear: both;
    line-height: 1.3rem;
}
.author-title {
    display: inline-block;
    clear: both;
    font-size: 0.8rem;
}

.recipe-mention-title {
    margin: 0;
    font-size: 1rem;
    color: #4d4d4d;
}

.recipe-profile-link:hover .author-name {
    color: #000;
}

.recipe-profile-link:hover .author-title {
    color: #000;
}

.btn.copy-clipboard {
    line-height: 1rem;
    color: #4a4a4a;
    text-shadow: none;
    // border: 1px solid #ececec;
    box-shadow: none;
    padding: 0 5px;
    background: 0;

    &.show {
        .copied-text {
            display: block;
            animation: fadeIn 0.5s ease-in-out;
        }
    }
    i {
        pointer-events: none;
        color: #000;
        text-shadow: none;
    }

    .icon {
        width: 25px;
        height: 25px;
        stroke: #4a4a4a;
    }

    &:hover {
        background-color: initial;
        box-shadow: none;
        color: #000;

        .icon {
            stroke: #000;
        }
    }
}

.copied-text {
    position: absolute;
    font-family: var(--charred-pepper);
    bottom: -28px;
    left: 11px;
    padding: 0 4px;
    border-radius: 4px;
    box-shadow: 0px 0px 1px #00000054;
    display: none;
    line-height: 2rem;
    color: #000;
    font-weight: 400;

    &::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #00000057;
        position: absolute;
        top: -5px;
        left: 26px;
    }
}

.banner-text {
    font-family: var(--toast-bread);
    font-variation-settings: var(--with-butter);
    text-align: center;
    font-size: 2.3rem;
    margin: 0;
    padding: 2.8rem 0 1.68rem 0;
}

.kitchen-banner {
    text-align: center;

    &-img {
        width: 100%;
        height: auto;
    }

    .kitchen-banner-title {
        text-align: center;
    }

    &::after {
        content: '';
        width: 100%;
        bottom: -1px;
        left: 0;
        box-shadow: -1px 0 3px #000000cc;
        height: 1px;
        position: absolute;
    }
}

.profile-banner {
    position: relative;
}

.profile-banner-title {
    font-size: 2rem;
    margin: 1rem 0 5px;
}

.profile-title,
.author-title {
    color: #4a4a4a;
}

.profile-title {
    margin: 0 0 1rem;
}

.verified-user {
    display: inline-block;
    height: 32px;
    vertical-align: middle;
}

.verified-check {
    color: var(--warm-carrot);
    font-size: 1.7rem;
}

.accessdenied {
    font-size: 2rem;
    text-align: center;
    margin: 6rem 0;
}

// Profile
.profile-banner {
}

.profile-avatar-wrapper {
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 5rem;
    background: #fff;
    box-shadow: inset 0 0 3px #00000038;
    overflow: hidden;

    .profile-preview {
        width: 100%;
    }
}

.profile-wrapper {
    margin-bottom: 1rem;
}

.no-avatar {
    font-size: 167px;
    line-height: 10rem;
    margin-left: -21px;
    color: var(--warm-apple);
}

.recipe-link {
    height: 125px;
    width: 100%;
    display: inline-block;
    background-position: center center;
    background-size: cover;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    filter: brightness(0.9);
    transition: var(--smooth-whiskey);

    &:hover {
        filter: brightness(1);
    }
}

.author-edit-recipe-link {
    position: absolute;
    top: 0px;
    right: 11px;
    padding: 0.2rem 0.3rem;
    background: #00000061;
    border-radius: 0 5px 0px 4px;

    &:hover .icon {
        stroke: #fff;
    }

    .material-icons {
        background: linear-gradient(45deg, black 25%, rgba(0, 0, 0, 0) 100%);
        border-radius: 4px;
    }

    .icon {
        width: 26px;
        height: 26px;
    }
}

.recipe-link-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 37px 5px 5px;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 92%
    );
    font-size: 1.2rem;
    font-weight: 500;
}

.private-recipe::before,
.mediabox-private-recipe .material-placeholder::before {
    background: var(--warm-apple);
    color: #fff;
    font-weight: 600;
    padding: 0rem 2.2rem 0.2rem;
    transform: rotate(-29deg) translate(-30px, -11px);
    position: absolute;
    width: 105px;
    height: 23px;
    content: 'private';
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: 0 0 2px #0000009c;
}

.btn {
    // font-family: var(--toast-bread);
    // font-variation-settings: var(--with-butter);
    font-family: var(--charred-pepper);
    text-transform: none;
    border-radius: 4px;
    // background: linear-gradient(to bottom, #2196f3 0%, #2196f3 100%);
    background: var(--warm-huckleberry);

    i {
        pointer-events: none;
    }

    &.clean {
        background: 0;
        border: 0;
        box-shadow: none;
        padding: 0;
        overflow: inherit;

        &.recipe-unstarred .waves-ripple {
            opacity: 0 !important;
        }
    }

    &.link {
        background: var(--warm-feijoa);
        transition: all 0.3s ease-in-out;
        &:hover {
            color: #fff;
            background: #67ba62;
        }
    }

    &.sign-out {
        background: var(--warm-grapefruit);

        &:hover {
            color: #fff;
            background: #ff333f8c;
        }
    }

    &:hover {
        background: #566fa6;
    }
}

.library-tab {
    background-color: initial;
    box-shadow: none;
    margin-bottom: 2rem;

    .tab {
        text-transform: inherit;
        margin: 0 5%;

        &:first-child {
            // margin: 0 5% 0 0;
        }

        a {
            color: #afafaf;
            font-size: 2rem;
            padding: 0;

            &.active {
                color: #000;
                &:focus {
                    background-color: inherit;
                }
                &:hover {
                    color: #000;
                }
            }
            &:hover {
                color: var(--warm-banana);
            }
        }
    }

    .indicator {
        // margin: 0 7%;
        margin-right: 0;
        background-color: var(--warm-banana);
    }
}

.recipes-list {
    opacity: 0;
    animation: fadeIn 1s 0.5s linear forwards;
}

// Glide core
.glide {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}
.glide * {
    box-sizing: inherit;
}

.glide__slides {
    position: relative;
    width: 100%;
    // list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
}
.glide__slides--dragging {
    user-select: none;
}
.glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    // user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.glide__arrows {
    -webkit-touch-callout: none;
    user-select: none;
}
.glide__bullets {
    -webkit-touch-callout: none;
    user-select: none;
}
.glide--rtl {
    direction: rtl;
}

.steps-list {
    padding: 0 0 0 2rem;
    margin: 0 0 1rem;
    display: block;
    overflow: inherit;
    font-family: var(--charred-pepper);
    list-style: none;

    .step {
        counter-increment: steps;
        position: relative;
        margin: 0 0 1.5rem;

        &::before {
            content: counter(steps) '.';
            // text-decoration: underline;
            font-size: 1.5rem;
            font-weight: 500;
            position: absolute;
            left: -35px;
            top: -6px;
            width: 30px;
            text-align: right;
        }

        strong {
            font-weight: 800;
        }
    }
}

.step {
    position: relative;

    p {
        margin: 0 0 1.5rem;
    }
}

.cm-step-timer {
    display: none;
    position: absolute;
    right: 0;
    top: 0px;
    // width: 174px;
    font-size: 2rem;
    text-align: right;

    .time-left {
        // width: 115px;
        text-align: left;
        display: inline-block;

        i {
            transform: translate(2px, 2px);
        }

        &.almost-done {
            animation: fadeIn 0.9s ease-in infinite;
        }
    }

    button {
        font-size: 0.9rem;
        width: 27px;
        height: 26px;
        margin: 0 3px 0;
        transform: translate(0px, 4px);
        background: var(--warm-date);
        vertical-align: text-top;

        i {
            font-size: 1rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.glide--slider {
    .glide__track {
        overflow: hidden;
    }
    .glide__slides {
        display: flex;
    }
}
.glide__arrows {
    display: none;
}

// Admin
.admin-page {
    h2 {
        margin: 0 0 10px;
    }
}

.notifications {
    overflow-y: scroll;
    max-height: 400px;
    border: 1px solid #ededed;
    background: #fff;

    li {
        display: flex;
        align-items: center;
        padding: 1rem;
        position: relative;
        margin: 0;

        .notification-link {
            display: inline-block;
            width: 100%;

            span {
                pointer-events: none;
            }

            &:hover {
                color: #000;
                .notification-title {
                    color: var(--warm-grapefruit);
                }
            }
        }

        .btn {
            margin: 0 0 0 10px;
            padding: 0 1rem;
            width: 120px;
            float: right;
            font-family: var(--charred-pepper);
        }

        .notification-title {
            font-size: 1.1rem;
            display: block;
            font-weight: 500;
        }

        .notification-time {
            display: inline-block;
            clear: both;
            font-size: 0.8rem;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 1000%;
            height: 1px;
            border-bottom: 1px solid #0000000a;
            left: 0;
        }

        &:first-child::after {
            content: inherit;
        }
    }
}

.notification-heading {
    font-size: 1.4rem;
}

// [type='checkbox'].reset-checkbox,
// [type='checkbox'].reset-checkbox:checked,
// [type='checkbox'].reset-checkbox:not(checked) {
//     opacity: 1;
//     position: relative;
// }

// [type='checkbox'].reset-checkbox + span::before,
// [type='checkbox'].reset-checkbox + span::after,
// [type='checkbox'].reset-checkbox:checked + span::before,
// [type='checkbox'].reset-checkbox:checked + span::after {
//     display: none;
// }

// [type='checkbox'].reset-checkbox + span:not(.lever) {
//     padding-left: 10px;
// }

[type='checkbox'] + span:not(.lever):before {
    border-radius: 4px;
}
[type='checkbox']:checked + span:not(.lever):before {
    border-right: 3px solid #000;
    border-bottom: 3px solid #000;
    border-radius: 3px;
}

// Help classes
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.delayed-fadein {
    opacity: 0;
    animation: fadeIn 0.5s 1s ease-in forwards;
}

// Library page
.library-public-msg {
    font-size: 1.2rem;
    padding: 1rem 2rem;
    border-left: 5px solid var(--warm-banana);

    .material-icons {
        font-size: 3rem;
        line-height: 1rem;
        vertical-align: text-top;
    }
    .icon {
        height: 33px;
        width: 33px;
        vertical-align: middle;
        margin-bottom: 5px;
    }
}

.link-to-login,
.link-to-register {
    font-size: 1.3rem;
    text-decoration: underline;
    font-weight: 600;
}

// DELETE THIS LATER
.beta-message {
    font-size: 0.8rem;
    width: 60%;
    margin: 0 auto;
    border-left: 2px solid var(--warm-elderberry);
    padding-left: 2rem;

    strong {
        font-size: 0.9rem;
        margin-bottom: -2px;
        display: block;
    }
}
.homepage-promo-link {
    color: var(--warm-date);
    font-weight: 700;
    font-size: 1rem;
    text-decoration: underline;
}

.new-food-people {
    margin: 0 0 15px;
    font-size: 1.2rem;
}

.food-people {
    .author-wrapper {
        margin-bottom: 10px;
    }
}
// END DELETER THIS LATER
