:root {
    --one-minute-rotate: 0deg;
    --one-hour-rotate: 90deg;
    --two-minute-rotate: 0deg;
    --two-hour-rotate: 90deg;
    --three-minute-rotate: 0deg;
    --three-hour-rotate: 90deg;
}

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$extra-large-and-up: 'only screen and (min-width : #{$large-screen-up})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;

.make-recipe {
    // position: fixed;
    // width: 100%;
    // height: 100%;
    // bottom: 0;
    // left: 0;
    // transform: translate3d(0, 100%, 0);
    transition: all 0.3s ease-in-out;
    // font-size: 1.2rem;
    // text-align: center;

    .list-heading {
        font-size: 2rem;
        margin: 1.4rem 0;
        font-family: var(--toast-bread);
        font-variation-settings: var(--with-butter);
    }
}

.recipe-creator-teaser {
    font-weight: 400;
}

.hide {
    transform: none;
}
.adding-items {
    li {
        // border: 1px dashed transparent;
        // transition: all .2s ease-in-out;
        text-align: left;
        // display: inline-block;
        // width: 70%;
        // margin: 0 auto;

        &:last-child {
            // animation: addeffect 0.3s ease-in-out;
        }

        // &:hover {
        //     border: 1px dashed #000;
        // }
    }
}

@keyframes addeffect {
    0% {
        will-change: opacity, translate3d;
        opacity: 0;
        transform: translate3d(5%, 0, 0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

.recipe-builder,
.edit-profile-form {
    .btn,
    .file-field span {
        font-family: var(--charred-pepper);
    }
}

.recipe-builder,
.login-form,
.register-form,
.edit-profile-form,
.create-list-modal {
    label {
        color: #131313;
        font-family: var(--charred-pepper);
        font-weight: 500;

        &.active {
            color: #131313;
        }
    }
    .row {
        margin: 0;
    }
    input,
    textarea {
        color: #000;

        &:focus {
            // box-shadow: 0 1px 0 0 #000 !important;
            box-shadow: none !important;
            border-color: #000 !important;
            // border-bottom: 1px solid var(--warm-elderberry) !important;

            + label {
                color: #000 !important;
            }
        }

        &.invalid {
            border-bottom: 1px solid var(--warm-apple) !important;
            box-shadow: none !important;
        }

        &.valid {
            border-bottom: 1px solid var(--warm-elderberry) !important;
            box-shadow: none !important;
        }
    }
    textarea,
    input {
        height: 4rem;
        line-height: 1.6rem;

        &::-webkit-input-placeholder {
            color: #000;
        }
    }

    .select-wrapper .caret {
        right: -2px;
        z-index: 1;
        pointer-events: none;
    }
}

.row.row-padding {
    margin: 2rem 0;
}

.edit-profile-form {
    margin: 2rem 0;
    display: inline-block;
}

.edit-profile-submit-wrapper {
    margin: 2rem 0;
}

.ingredients-container,
.steps-container,
.special-mentions-container {
    padding: 1em;
    margin: 2.5rem 0;
    border-radius: 6px;
}

.step-wrapper,
.ingredient-wrapper,
.special-mentions-wrapper {
    border: 3px solid #afafaf;
    border-radius: 6px;
    background: #fff;
}
.item-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 85px !important;
}
.item-add-btn {
    margin: 0;
    padding: 0 20px;
}

.step-add-button {
    margin: 1rem 0.5rem;
}

.step-wrapper .step-controls {
    padding: 1rem 0;
}

.step-wrapper .range-wrapper {
    margin-bottom: 15px;
    padding: 0;

    .range-slider-text {
        top: -8px;
    }
}

.floating-box {
    border-radius: 6px;
    padding: 10px;
    position: relative;
    box-shadow: 0px 1px 3px #00000040;

    &:after {
        // .table-of-contents-shadow {
        content: '';
        bottom: -7px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        filter: blur(7px);
        height: 7px;
        left: 3%;
        position: absolute;
        width: 94%;
        z-index: -1;
    }
}
@media #{$small-and-down} {
    .home-link-text {
    }

    .thumbnail-frame {
        padding: 0 !important;

        .material-placeholder {
            border-radius: 0;
        }
    }

    .step-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
    .floating-box {
        width: 94% !important;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, 0);
        display: none;

        &.pinned {
            opacity: 0;
            animation: fadeIn 0.2s linear forwards;
        }
    }

    .enter-cm-btn.top {
        right: 50%;
        transform: translate(50%, -220%);
        display: flex;
        display: none; // Testing if too many buttons
    }

    .enter-cm-btn.middle {
        margin: 5px 0 0;
        transform: translate(0%, -14%);
        display: flex;
        display: none; // Testing if too many buttons
    }
    .enter-cm-btn.bottom {
        transform: translate(-50%, -14%);
        left: 50%;
    }
    .cooking-mode .ingredients {
        overflow-y: scroll;
        width: 70%;
    }

    .author-toolbar {
        padding: 0.9rem 24%;
    }

    .library-tab .tab a {
        font-size: 1.4rem;
    }

    .selection-list {
        .item-selected {
            .list-action {
                &:after {
                    content: '';
                    position: absolute;
                    width: 11px;
                    height: 18px;
                    border-right: 5px solid var(--warm-feijoa);
                    left: 9px;
                    top: 7px;
                    border-bottom: 5px solid var(--warm-feijoa);
                    transform: rotate(45deg);
                }
            }
        }
        .list-action {
            &:before {
                content: '';
                position: absolute;
                width: 15px;
                height: 15px;
                border: 1px solid #828282;
                left: 8px;
                top: 11px;
                border-radius: 3px;
                background: #efefef;
                opacity: 0.2;
            }
        }
        .material-icons {
            display: none;
        }
    }
}

// @media #{$medium-and-down} {
// styles for medium screens and down
.time-set {
    display: block;
}
// }

.box-container {
    border-radius: 10px;
    border: 1px solid #00000014;
    padding: 10px;
    position: relative;

    &:before {
        content: '';
        bottom: 0;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        filter: blur(6px);
        height: 94%;
        left: 3%;
        position: absolute;
        width: 94%;
        z-index: -1;
    }
}

.table-of-contents {
    margin: 15px 15px 0 0;
    background: #f1f1f1;

    h3 {
        margin: 10px 0 0 1rem;
        font-size: 2rem;
    }

    li {
        padding: 0;
        overflow: hidden;
    }

    a {
        color: #000;
        position: relative;
        z-index: 1;
        font-weight: 300;

        &.active,
        &:hover {
            border-color: var(--warm-apple);
            color: #5e5e5e;
        }

        &:hover:before {
            content: '';
            width: 100%;
            height: 0;
        }
    }

    .icon {
        font-size: 1rem;
        vertical-align: middle;
        line-height: 1.3rem;
        margin: -3px 0px 0 3px;
        animation: addeffect 0.6s ease-in-out;
        width: 13px;
        height: 13px;
    }

    .section-list {
        font-size: 0.7rem;
        margin: 0 0 0 1.5rem;
        display: none;
        // height: 0;
        // transform: translate(0px, -100%);
        // transition: var(--smooth-whiskey);
        // opacity: 0;

        li {
            padding: 5px 0;
            font-size: 0.8rem;
            color: #4a4a4a;
        }
    }

    .active + .section-list {
        // height: 88px;
        // transform: none;
        // opacity: 1;
        display: block;
    }

    .check-wrapper,
    .required-items {
        font-size: 0.7rem;
        margin: 0 0 0 3px;
        pointer-events: none;
    }

    &.pinned {
        width: inherit;
    }
}

.section-anchor {
    margin-bottom: 15px;
}

input[type='range'] {
    border: none;
}

.recipe-maker-banner {
    text-align: center;
}

input.select-dropdown.dropdown-trigger {
    height: 2.5rem;
    margin: 0.5rem 0;
    padding: 0px 0 2px 0;
    font-size: 0.9rem;
    font-weight: 500;
    box-sizing: border-box;
    line-height: 2.1rem;
}

.dropdown-content {
    border-radius: 6px;

    &.select-dropdown li.selected {
        background: rgba(255, 255, 255, 0.2);

        span {
            background-color: #f7f7f7;
        }
    }

    li {
        min-height: 25px;

        > span {
            color: #5d5d5d;
            font-family: var(--charred-pepper);
            font-size: 12px;
            line-height: 25px;
            padding: 6px 10px;
            font-weight: 600;
        }
    }
}

.character-counter {
    position: absolute;
    right: 10px;
    bottom: -12px;
    font-size: 0.7rem !important;
}

.input-field.file-field {
    .btn {
        width: 40%;
        line-height: 1.2rem;
        font-size: 0.7rem;
        text-transform: none;
        vertical-align: middle;
        display: inline-block;
        margin-top: 0px;
        height: auto;
        padding: 10px;
    }
    input.file-path {
        font-size: 0.8rem;
    }
}

#fullDetails {
    ~ .helper-text:after {
        color: #000;
        font-family: var(--charred-pepper);
        position: relative;
        animation: fadeIn 1s ease-in forwards;
    }
}

.range-slider.noUi-horizontal .noUi-handle {
    border-radius: 20px;
    width: 28px;
    right: -14px;
    box-shadow: 0px 0px 2px #0000004d;
    top: -8px;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
        box-shadow: 0px 0px 4px #00000087;
    }

    &:focus {
        outline-style: dashed;
        outline-width: medium;
        outline-color: inherit;
    }

    &:before {
        // left: 13px;
        // top: 5px;
        // height: 10px;
        // width: 2px;
        left: 12px;
        top: 3px;
        height: 11px;
        width: 2px;
        transform-origin: bottom center;
        background: #848484;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
    }
    &:after {
        left: 12.4px;
        top: 0px;
        transform-origin: bottom center;
        background: #797979;
        border-radius: 10px;
        box-shadow: 0 0 1px #000;
        transition: all 0.3s ease-in-out;
    }
}

.range-slider {
    &.slider-one.noUi-horizontal .noUi-handle {
        &:before {
            transform: rotate(var(--one-hour-rotate));
        }
        &:after {
            transform: rotate(var(--one-minute-rotate));
        }
    }
    &.slider-two.noUi-horizontal .noUi-handle {
        &:before {
            transform: rotate(var(--two-hour-rotate));
        }
        &:after {
            transform: rotate(var(--two-minute-rotate));
        }
    }
    &.slider-three.noUi-horizontal .noUi-handle {
        &:before {
            transform: rotate(var(--three-hour-rotate));
        }
        &:after {
            transform: rotate(var(--three-minute-rotate));
        }
    }
}

.range-wrapper {
    padding: 1rem;
    position: relative;
    label {
        margin-bottom: 10px;
        display: inline-block;
        font-size: 0.9rem;
    }
    .noUi-target {
        border-radius: 6px;
        border: 0;
        box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
    }
    .noUi-connects {
        border-radius: 6px;
        box-shadow: inset 0px 0 2px #9a9a9a;
        background: #ffffff;
    }
    .noUi-horizontal {
        height: 13px;
    }
}
.range-slider-text {
    position: absolute;
    padding: 10px;
    right: 0px;
    text-align: center;
    font-size: 0.9rem;
    top: 4px;
    text-decoration: underline;
    font-weight: 500;
}

.cook-time {
    .noUi-connect {
        background: var(--warm-date) !important;
    }
}
.prep-time {
    .noUi-connect {
        background: var(--warm-banana) !important;
    }
}

.file-path-wrapper {
    display: none;
}

.thumbnail-wrapper {
    border-radius: 6px;
}

.no-thumbnail {
    background: #fff;
    padding: 2rem 1rem;
    border-radius: 6px;
    font-family: var(--charred-pepper);
    border: 1px solid #bdbdbd;
    text-align: center;
    color: #6f6f6f;
}

.file-size-helper {
    margin-top: -10px;
    font-size: 0.8rem;
    color: #6f6f6f;
}

.profile-title-helper {
    font-size: 0.8rem;
    margin: 0;
}

.funnyguy-helper-text {
    display: inline-block;
    margin: 0 0 5px 0;
}

.input-field.file-field .btn {
    width: 100%;
    font-size: 1rem;
}

.thumbnail-preview-wrapper {
    max-width: 118px;
    padding: 0.6rem 0;
}

.thumbnail-preview {
    width: 100%;
    height: auto;
}

.list-steps {
    margin: 0 0 0 0.5rem;
    padding: 0 0 0 1rem;
    list-style: none;
}

.list-item {
    position: relative;
    padding-right: 75px;
    margin-bottom: 20px;

    counter-increment: steps;
    position: relative;

    &::before {
        content: counter(steps) '.';
        // text-decoration: underline;
        font-size: 1.2rem;
        font-weight: 500;
        position: absolute;
        left: -21px;
        top: -2px;
    }

    .step-has-timer {
        right: 55px;
        position: absolute;
        top: 0;
    }

    strong {
        font-weight: 800;
    }
}

.step-control,
.ingredient-control {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: 10px;
    cursor: pointer;
    opacity: 0.75;
    right: 0;
    top: 0;

    .material-icons {
        font-size: 0.9rem;
        font-weight: bold;
        position: absolute;
        left: 3.5px;
        top: 3.5px;
        pointer-events: none;
    }

    &:active {
        // top: initial;
    }

    &:hover {
        opacity: 1;
    }

    &.edit-step {
        right: 27px;
    }
}
.step-adder {
    margin: 0;
    + .helper-text {
        display: none;
        &::after {
            // content: 'More step features to come!';
            // color: #fff;
        }
    }
}
.step-hint {
    // margin: -20px 0 10px;
}

.timer-icon {
    margin: 0 0.1rem 0 0.5rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    vertical-align: sub;
}

#steps {
    height: 3.4rem;
    padding: 0.8rem 0 0 0;
}

.no-items-message {
    display: flex;
    align-items: center;

    .material-icons {
        font-size: 2rem;
    }
}

.preview {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    transform: perspective(100px) translate3d(0, 100%, 0);
    border-radius: 6px;
    overflow-y: scroll;
    // background-image: linear-gradient(
    //         90deg,
    //         rgba(0, 0, 0, 0.01) 50%,
    //         transparent 50%
    //     ),
    //     linear-gradient(rgba(0, 0, 0, 0.01) 50%, transparent 50%);
    background: #fff;
    background-size: 50px 50px;
    transition: var(--smooth-whiskey);
    z-index: 1;
    border: 2px solid #e0e0e0;
    will-change: translate3d, perspective;

    .recipe {
    }

    &.show {
        transform: perspective(100px) translate3d(0, 0, -2px);
        z-index: 10;
    }
    // &::after {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     // background: #fff;
    //     z-index: -1;
    // }
}

.preview-title {
    position: absolute;
    top: -10px;
    font-size: 2rem;
    text-align: center;
    width: 100%;

    span {
        background: var(--warm-apple);
        padding: 1rem;
        border-radius: 6px;
        border: 2px solid #fff;
        box-shadow: 0 0 1px #000;
    }
}

.exit-preview {
    position: absolute;
    right: 0;
    margin: 10px 10px 0 0;
    top: 0;
    font-family: var(--charred-pepper);
    height: 40px;
    padding: 0 1rem;
}

.recipe-action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
}

.submit-btn {
    background: var(--warm-feijoa);

    &:hover {
        background: #67ba62;
    }
}

.recipe-action-btn-text {
    line-height: 1.1rem;
}

.visually-hide {
    // opacity: 0;
    // transition: all 0.3s ease-in-out;
    z-index: -2;
}

.loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #39818142;
    z-index: 10;
    opacity: 1;
    will-change: opacity;

    &.animate-in {
        animation: fadeIn 0.7s cubic-bezier(0.6, 0.13, 0.23, 0.96) forwards;
    }

    &.animate-out {
        // animation: fadeOut 0.3s 0.6s linear forwards,
        //     sendIt 0.1s 0.9s linear forwards;
        animation: fadeOut 0.3s 0.6s linear forwards;

        .loading-text {
            animation: fadeIn 0.4s cubic-bezier(0.6, 0.13, 0.23, 0.96) forwards;
        }
    }

    &.in-progress {
        opacity: 0;
        animation: fadeIn 0.5s linear forwards;
    }

    .loading-svg {
        width: 90px;
    }

    .loading-box {
        width: 90px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        &::before {
            content: '';
            width: 140px;
            height: 140px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #f3f3f3;
            border-radius: 100%;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.42);
            filter: blur(1px);
            z-index: -1;
        }
        .pie {
            .steam {
                stroke-dasharray: 136;
                animation: dash 1.5s linear infinite;
            }

            @keyframes dash {
                0% {
                    stroke-dashoffset: 136;
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                    stroke-dashoffset: 408;
                }
            }
        }

        .tick {
            stroke: #5da758;
            stroke-width: 50;

            &-part-1 {
                stroke-dasharray: 173;
                stroke-dashoffset: 519;
            }

            &-part-2 {
                stroke-dasharray: 314;
                stroke-dashoffset: 314;
            }

            @keyframes tickpartone {
                to {
                    will-change: opacity;
                    opacity: 1;
                    stroke-dashoffset: 346;
                }
            }
            @keyframes tickparttwo {
                to {
                    stroke-dashoffset: 628;
                }
            }
        }

        &.task-complete {
            animation: fadeOut 0.6s 1s linear forwards;
            .pie {
                display: none;
            }
            .tick-part-1 {
                animation: tickpartone 0.22s linear forwards;
            }

            .tick-part-2 {
                animation: tickparttwo 0.22s 0.18s linear forwards;
            }
        }
    }

    .loading-text {
        margin: 0;
        text-align: center;
        color: #272727;
        // text-shadow: 0px 1px 1px #a5a5a5;
        // font-variation-settings: 'wght' 1000;
        will-change: opacity;
        display: inline-block;
        height: 44px;
        line-height: 1.2rem;
        padding: 5px 0 0 0;
        font-size: 1.1rem;
        font-weight: 500;
    }
}

.underline {
    text-decoration: underline;
}

svg,
.icon {
    pointer-events: none;
}

.edit-step-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #ffffffd6;
    z-index: 2;

    .edit-step {
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 65%;

        .close {
            position: absolute;
            top: -45px;
            right: 0;
        }
    }
}
