.btn.user-fan {
    width: 36px;
    height: 36px;
    // border: 1px solid #b9b9b9;
    display: inline-block;
    // background: #f1f1f1;
    vertical-align: middle;

    .icon {
        width: 31px;
        display: inline-block;
        /* stroke: var(--warm-elderberry); */
        height: 33px;
        stroke-width: 0.3;
        fill: #000;
    }
}
